import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import './scss/Form.scss';
import contact from './media/contact.png';

function Contact() {
  const [fromName, setFromName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [replyTo, setReplyTo] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [formState, setFormState] = useState(null);

  const form = useRef();

  useEffect(() => {
    if (emailRegex.test(replyTo) && message.length > 0) {
      setIsFormValid(true)
    } else {
      setIsFormValid(false)
    }
  });

  const emailRegex = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;

  function resetForm() {
    setFromName('')
    setCompanyName('')
    setReplyTo('')
    setSubject('')
    setMessage('')
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isFormValid) {
      setFormState('submitting')
      emailjs.sendForm('service_ifpju1e', 'template_q7miqum', form.current, 'NPS2MzqzwEDNnrj9D')
      .then((result) => {
          console.log(result.text);
          setFormState('submitted');
          resetForm();
      }, (error) => {
          console.log(error.text);
          setFormState('submissionError');
      });
    } else {
      setFormState('invalidSubmission')
    }
    
  };

  return (
    <div id="contact-page">
      <h1>Contact Us</h1>
      <div className="flex">
        <div id="contact-form">
          <p>For more information about our company and/or services, please contact us using the form below:</p>

          <form ref={form} onSubmit={handleSubmit} className={formState === 'submitting' ? 'submitting' : null}>
            
            <div className="section">
              <label htmlFor="from_name">Name</label>
              <input 
                type="text" name="from_name" id="from_name" placeholder="Name"
                value={fromName}
                onChange={e => setFromName(e.target.value)}
              />
            </div>
            
            <div className="section">
              <label htmlFor="company_name">Company</label>
              <input 
                type="text" name="company_name" id="company_name" placeholder="Company"
                value={companyName}
                onChange={e => setCompanyName(e.target.value)}
              />
            </div>
            
            <div className="section">
              <label htmlFor="reply_to">Email</label>
              <input 
                type="text" name="reply_to" id="reply_to" placeholder="Email"
                value={replyTo}
                onChange={e => setReplyTo(e.target.value)}
              />
            </div>
            
            <div className="section">
              <label htmlFor="subject">Subject</label>
              <input 
                type="text" name="subject" id="subject" placeholder="Subject"
                value={subject}
                onChange={e => setSubject(e.target.value)}
              />
            </div>
            
            <div className="section">
              <label htmlFor="message">Message</label>
              <textarea 
                type="text" name="message" id="message" placeholder="Message"
                value={message}
                onChange={e => setMessage(e.target.value)}
              />
            </div>

            <input type="submit" value={formState === 'submitting' ? 'Sending...' : 'Send'} className={isFormValid ? 'enabled' : 'disabled'} />

            {formState === 'invalidSubmission' &&
              <div className="form-status error">ⓘ The form requires a valid email and a message</div>
            }

            {formState === 'submissionError' &&
              <div className="form-status error">ⓘ There was an error submitting the form. Please try again.</div>
            }

            {formState === 'submitted' &&
              <div className="form-status success">✓ Your message has been sent. Thank you for contacting us.</div>
            }
          </form>
        </div>
        <div id="contact-image">
          <img src={contact} />
        </div>
      </div>
    </div>
  )
}

export default Contact;