import researchDevelopmentImg from './media/researchDevelopment.png';

function ResearchDevelopment() {
  return (
    <div id="research-development-page">
      <h1>Research & Development</h1>
      <div className="flex">
        <div>
          <p>TauMat technologists have their roots in basic and applied R&D.  We pride ourselves in an art-of-the-possible, agile, startup operating philosophy.  Our goal is to innovate fast to deliver cutting-edge products to our customers.</p>
          <p>TauMat provides a variety of services in the field of thermal engineering. We specialize in every aspect of design optimization for maximum performance in transient thermal environments. Our phase change thermal energy storage systems greatly enhance performance of electronics and photonics with finite duty cycles and/or variable load profiles utilizing compact, structurally robust materials that can withstand high cycles and extreme environments.</p>
          <p>We can provide:</p>
          <ul>
            <li>Thermal modeling at the material, component, and system levels</li>
            <li>Heat sink, cold-plate, and heat exchanger design and optimization based on specific thermal load and duty cycle requirements</li>
            <li>Small-batch manufacturing of custom thermal energy storage components</li>
          </ul>

          <h3>We are eager to discuss options for innovative solutions and new markets.  Please reach out to schedule a meeting with our team to discuss your challenges and how we may solve them.</h3>
        </div>
        <div>
          <img src={researchDevelopmentImg} />
        </div>
      </div>
    </div>
  )
}

export default ResearchDevelopment;