import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import './scss/Header.scss';
import logo from './media/logo.png';

function Header() {

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  let location = useLocation();

  useEffect(() => {
    setMenuIsOpen(false);
  }, [location]);

  function toggleMenu() {
    setMenuIsOpen(!menuIsOpen)
  }

  function handleKeyPress(e) {
    if (e.key === 'Enter') {
      toggleMenu();
    }
  }
  
  const pages = [
    { title: "Company", url: "company"},
    { title: "Defense", url: "defense"},
    { title: "Electronics & Energy", url: "electronics-energy"},
    { title: "Research & Development", url: "research-development"},
    { title: "Contact us", url: "contact"},
  ]

  function NavLinks() {
    return (
      <nav>
        {pages.map(page => 
          <NavLink to={page.url} key={page.url}>{page.title}</NavLink>
        )}
      </nav>
    )
  } 
  
  return (
    <header>
      <NavLink to="/"><img src={logo} className="logo"/></NavLink>
      <div id="nav-menu" className={menuIsOpen ? "open" : "closed"}>
        <div 
          id="nav-menu-button" 
          tabIndex="0" 
          onClick={toggleMenu}
          onKeyDown={handleKeyPress}>
        </div>
        <NavLinks />
      </div>
      
    </header>
  )
}

export default Header;