import * as React from "react";
import * as ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from './Root';
import Home from './Home';
import Error from "./Error";
import Company from './Company';
import Defense from './Defense';
import ElectronicsEnergy from './ElectronicsEnergy';
import ResearchDevelopment from './ResearchDevelopment';
import Contact from './Contact';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <Error />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "company",
        element: <Company />
      },
      {
        path: "defense",
        element: <Defense />
      },
      {
        path: "electronics-energy",
        element: <ElectronicsEnergy />
      },
      {
        path: "research-development",
        element: <ResearchDevelopment />
      },
      {
        path: "contact",
        element: <Contact />
      },
    ],
  },
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);