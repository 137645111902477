import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import './scss/Root.scss';

function Error() {
  return (
    <>
    <Header />
    <div id="page-content">
      <div id="error-page">
      <h1>404 Error — Page not found</h1>
      <p>The page you have navigated to does not exist.</p>
      <Link to="/">Return home</Link>
    </div>
    </div>
    <Footer />
    </>
  )
}

export default Error;