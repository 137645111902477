import './scss/Footer.scss';
import logo from './media/logo-small.png';

function Footer() {
  return (
    <footer>
      <img src={logo} className="logo" />
      <div id="footer-contact">
        <a href="tel:+1301-593-3019" itemProp="telephone">+1 (301) 593-3019</a>
        <span className="dot">•</span>
        <a href="mailto:info@taumat.tech" itemProp="email">info@taumat.tech</a>
        <span className="dot">•</span>
        <div itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
        <span itemProp="addressLocality">Silver Spring</span>,
        <span itemProp="addressRegion"> MD</span>
      </div>
      </div>
    </footer>
  )
}

export default Footer;