import charger from './media/charger.png';
import heat_sink from './media/heat_sink.png';

function ElectronicsEnergy() {
  return (
    <div id="electronics-energy-page">
      <h1>Thermal Management for Electronics & Energy</h1>
      <div className="flex">
        <div><p>With the passing of the CHIPS and Science Act last year, our nation has emphasized (in the clearest way possible) that semiconductor manufacturing and technology is a top national priority. These components are used in everything from electric vehicles, computing devices, internet of things, and other distributed energy, sensing, and computing sources. To enable this trend towards a more-electrified world, we need more efficient dynamic thermal management approaches to meet the requirements of these core technologies.</p></div>
        <div><img src={heat_sink} alt="heat sink" /></div>
      </div>

      <h3>Leveraging defense innovation</h3>
      <div className="flex reverse">
        <div><img src={charger} alt="charger" /></div>
        <div><p>TauMat technologists have a history of developing thermal management techniques for high power electronic and photonic DOD applications. These advanced thermal management approaches are synergistic with these overarching goals of a more-electrified world. Leveraging our experience in high-power fast-transient defense applications, we are poised to develop advanced cooling technologies for the energy sector. Our researchers are investigating applications in power electronics, battery fast charging, thermal protection, high performance computing, and high-temperature superconducting systems.  As we expand, we expect cross-over to additional markets and use-cases.</p></div>
      </div>
    </div>
  )
}

export default ElectronicsEnergy;