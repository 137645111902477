import defenseImg from './media/defense.png';

function Defense() {
  return (
    <div id="defense-page">
      <h1>Thermal Management for Defense</h1>
      <div className="flex">
        <div>
          <p>Current and future radio frequency (RF), high power microwave (HPM), and high-energy laser (HEL) directed energy (DE) assets offer profound advantages in layered defense and special offense domains. These systems are theoretically capable of unlimited magazines, however, available power and associated thermal management systems currently limit the weapon’s magazine depth and duty cycle. Continuous (steady-state) air, liquid, and vapor compression cooling can be scaled-up to handle high thermal loads but the additional size, weight and power (SWaP) required for such scaled-up thermal management systems makes these approaches impractical. Despite this, the prevailing design and implementation strategies for cooling and thermal management relies almost exclusively on steady-state design that favors a minimization of thermal resistance.  This completely ignores system time constants (τ) and poses a clear inconsistency between design and operation modalities in emerging systems.</p>

          <h3>Transient thermal management</h3>
          <p>TauMat is developing transient thermal management approaches to balance high-power needs with DOD platform considerations. One promising approach is the use of thermal energy storage (TES) to passively store and release thermal energy.  The combination of TES and pulsed power operation lowers the time-averaged thermal load on the primary coolant loop. This, in turn, reduces the overall thermal management power consumption, enables a reduction (or complete elimination) in the size of ancillary components (fluid reservoirs, radiators, fans, heat exchangers, and pumps), and provides a method for balancing capability and practicality on SWaP-constrained platforms.</p>
          <p>Combining our advanced modeling approaches, new solid-state phase change materials, and novel packaging approaches, TauMat is committed to developing cutting-edge DE thermal management and end-effect capabilities for maintaining DOD overmatch.</p>
        </div>
        <div>
          <img src={defenseImg} />
        </div>
      </div>
    </div>
  )
}

export default Defense;