import home from './media/home.png';
import homeMobile from './media/homeMobile.png';

function Home() {
  return (
    <div id="home-page">
      <h1>Dynamic Solutions for a Dynamic World</h1>
      <p>TauMat is a deep technology company developing dynamic thermal management software and hardware solutions to enable unprecedented high-power, fast-transient, defense and commercial applications.  With an emphasis on innovation, we are reimagining thermal management to bring future high-power capabilities to life.</p>
      <picture>
        <source media="(min-width: 750px)" srcSet={home} />
        <source media="(max-width: 749px)" srcSet={homeMobile} />
        <img src={home} />
      </picture>
    </div>
  )
}

export default Home;