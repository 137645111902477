import './scss/Pages.scss';
import darin from './media/darin.png';
import asher from './media/asher.png';

function Company() {
  return (
    <div id="company-page">
      <h1>About TauMat</h1>
      <div className="flex">
        <div><p>TauMat, LLC was established in January 2023. Our mission is to develop materials, components, and systems to provide performance, economic, and competitive advantage for military and commercial electronic, photonic, and transient thermal applications; our services include material-, device-, component-, and system-level modeling, design, experimentation, and product delivery.</p></div>
        <div id="company-photos">
          <figure>
            <img src={darin} alt="Dr. Darin Sharar" />
            <figcaption>Dr. Darin Sharar</figcaption>
          </figure>
          <figure>
            <img src={asher} alt="Dr. Asher Leff" />
            <figcaption>Dr. Asher Leff</figcaption>
          </figure>
        </div>
      </div>
      <p>Our founders are Dr. Darin Sharar (CTO) and Dr Asher Leff (CEO). Dr. Sharar and Dr. Leff hold PhDs in mechanical engineering and materials science and engineering, respectively.  Prior to the founding of TauMat, Dr. Sharar worked as an engineer at the U.S. Army Research laboratory for 15 years; he is a recognized expert in the specialized fields of thermal management, materials, and high-power electronics with a proven track record of technical and programmatic development/leadership on high-impact internal-, DARPA-, and customer-funded programs.  Notably, he was awarded DOD Scientist of the Quarter (Fiscal Year 2022) amongst the over 40,000 DOD scientists nationally for his discovery of Martensitic transformations for thermal energy storage.  Dr. Leff worked as a scientist at the U.S. Army Research laboratory, serving as the head of transmission electron microscopy and as a cross-cutting subject matter expert for materials characterization and processing for the Energy Sciences Division. In recognition of that expertise, Leff was appointed and currently serves as an editor for the peer-reviewed Journal of Materials Science: Materials in Electronics.  Collectively, they have over 25 years of R&D experience in the fields of thermal management, metallurgical processing, materials characterization, and prototyping.</p>
      <p>“You have proven yourself as a subject matter expert in your work on thermal materials…and advanced heat transfer programs. It is particularly impressive how you discovered the use of metallic solid-state phase-change materials and led programs that have rapidly advanced the Department’s ability to design, tailor, fabricate, and apply these new multifunctional materials…your leadership in support of these efforts has been unparalleled…your efforts and contributions have proven invaluable and deserve recognition.” -Barbara McQuiston (SES), then-Director of Defense Research and Engineering for Research and Technology (DDR&E(R&T))</p>
      
    </div>
  )
}

export default Company;