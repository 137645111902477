import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import './scss/Root.scss';

function Root() {
  return (
    <>
    <Header />
    <div id="page-content">
      <Outlet />
    </div>
    <Footer />
    </>
  )
}

export default Root;